import type { FC } from 'react'

import { CWHeading, CWHeadingHeader } from '@features/common.components'
import { isFieldType, isStringField } from '@shared/openapi/guards'
import classNames from 'classnames'

import { Parameter } from '../Parameter/Parameter'

import type { ParameterProps } from '../Parameter/Parameter'
import type {
  OperationParameter,
  TransformedResponseParameters,
} from '@features/playground.graphql'

export type ParameterSchema = {
  /**
   * Indicates the minimum value of the parameter.
   */
  min?: number
  /**
   * Indicates the maximum value of the parameter.
   */
  max?: number
  /**
   * Indicates the default value of the parameter.
   */
  default?: number
} & object

export interface ParameterSectionProps {
  /**
   * The title of the parameter section.
   */
  title: string
  /**
   * The list of parameters to be displayed in the section.
   */
  parameters?:
    | OperationParameter[]
    | TransformedResponseParameters['childParameters']
}

export const ParameterSection: FC<ParameterSectionProps> = ({
  title,
  parameters,
}) => {
  if (!parameters || parameters.length === 0) return null

  //Sort required parameters to the top
  const sortedParameters = parameters
    .filter((param) => param != null)
    .toSorted((a, b) => {
      if (a!.required) return -1
      return b!.required ? 1 : 0
    })

  return (
    <div>
      <CWHeading variant="section/md">
        <CWHeadingHeader className="font-circular-bold text-neutral-strong">
          {title}
        </CWHeadingHeader>
      </CWHeading>
      <div className="divide-y divide-neutral-subtle">
        {sortedParameters.map((maybeParam) => {
          const param = maybeParam!

          if ('content' in param) {
            return (
              <div
                key={param.name}
                className={classNames({
                  'py-2': param.name.includes('option'),
                })}
              >
                <Parameter {...(param as ParameterProps)} />
              </div>
            )
          }

          const schema = param.schema as ParameterSchema
          return (
            <Parameter
              key={param.name}
              content={param.description}
              defaultValue={schema.default}
              name={param.name}
              range={
                schema.min != null && schema.max != null
                  ? [schema.min, schema.max]
                  : undefined
              }
              required={param.required}
              type={getType(param)}
            />
          )
        })}
      </div>
    </div>
  )
}

/**
 * Helper function to get parameter type.
 * @param parameter - OperationParameter.
 * @returns String.
 */
function getType(parameter: OperationParameter): string {
  if (isStringField(parameter.schema) && parameter.schema.format) {
    return parameter.schema.format
  }

  if (isFieldType(parameter.schema)) {
    const paramType = parameter.schema.type

    if (
      (paramType === 'oneOf' || paramType === 'anyOf') &&
      'fields' in parameter.schema
    ) {
      return parameter.schema.fields[0].type
    }

    return paramType
  }

  return ''
}
