import { Radio } from '@circlefin/components'
import { useController } from '@circlefin/form'
import useTranslation from 'next-translate/useTranslation'

export const FeedbackScore: React.FC = () => {
  const { field } = useController<{ score: number }, 'score'>({
    name: 'score',
  })
  const { t } = useTranslation('common')

  return (
    <Radio.Group
      className="flex justify-center items-center"
      label={t`modals.sendFeedback.score.label`}
      name="score"
      onChange={field.onChange}
      value={field.value}
    >
      <span className="type-body-xs flex items-center w-full">{t`modals.sendFeedback.score.notHelpful`}</span>
      <Radio value={1}>1</Radio>
      <Radio value={2}>2</Radio>
      <Radio value={3}>3</Radio>
      <Radio value={4}>4</Radio>
      <Radio value={5}>5</Radio>
      <span className="type-body-xs flex items-center">{t`modals.sendFeedback.score.helpful`}</span>
    </Radio.Group>
  )
}
