import { useCallback, useEffect, useMemo, useState } from 'react'

import { useFieldArray, useFormContext } from '@circlefin/form'
import {
  CWAccordionPanel,
  CWAccordionPanelContent,
  CWAccordionPanelHeader,
  CWIcon,
} from '@features/common.components'

import { FormInputField } from '../../FormInputField'
import { getInputLabel } from '../../getInputLabel/getInputLabel'

import { AddNewButton } from './AddNewButton/AddNewButton'

import type { OnSelect } from './AddNewButton/AddNewButton'
import type { FormInputFieldProps } from '../../types'
import type { FieldType } from '@shared/openapi/transform/field/types'
import type { Field } from '@shared/openapi/types'

/**
 * Props for the ArrayPrimitiveInputField component.
 * Extends FormInputFieldProps with a Field.ArrayField schema.
 */
export type ArrayPrimitiveInputFieldProps =
  FormInputFieldProps<Field.ArrayField>

/**
 * A form input field for defining array values. Supports boolean, string, number, integer, object and oneOf.
 * @param props - The component props.
 * @returns The rendered ArrayPrimitiveInputField component.
 */
export const ArrayPrimitiveInputField: React.FC<
  ArrayPrimitiveInputFieldProps
> = ({ schema, name, prefix }) => {
  const controlName = prefix ? `${prefix}.${name}` : name
  const { setFocus } = useFormContext()
  const label = useMemo(() => getInputLabel({ schema, name }), [schema, name])

  const { fields, append, remove } = useFieldArray({
    name: controlName,
  })

  const [schemasToCreate, setSchemasToCreate] = useState<FieldType[]>([])

  const onSelect = useCallback<OnSelect>(
    (schema, defaultValue) => {
      setSchemasToCreate((prev) => [...prev, schema])
      append(defaultValue)
    },
    [append],
  )

  const removeSchema = useCallback(
    (index: number) =>
      setSchemasToCreate((prev) => prev.filter((_, i) => i !== index)),
    [],
  )

  useEffect(() => {
    let id: NodeJS.Timeout | undefined
    if (schemasToCreate.length > 0) {
      id = setTimeout(
        // this needs to be executed after dom has updated
        () => setFocus(getControlNameToFocus(schemasToCreate, controlName)),
        0,
      )
    }
    return () => clearTimeout(id)
  }, [schemasToCreate, controlName, setFocus])

  const onRemove = useCallback(
    (index: number) => () => {
      removeSchema(index)
      remove(index)
    },
    [remove, removeSchema],
  )

  return (
    <div className="w-full" data-testid="array-object-field">
      <label className="text-sm font-circular-bold">{label}</label>
      {fields.map((field, index) => {
        return (
          <CWAccordionPanel
            key={field.id}
            className="my-1 [&_.cb-card]:border-neutral [&_.cb-card]:rounded-sm [&_.accordion-panel-header-icon]:pl-4"
            open
          >
            <CWAccordionPanelHeader>
              <div className="text-left capitalize">{schema.items.type}</div>
              <CWIcon
                aria-label="Close"
                className="float-right"
                name="TrashOutline"
                onClick={onRemove(index)}
                size={24}
              />
            </CWAccordionPanelHeader>
            <CWAccordionPanelContent>
              <FormInputField
                key={field.id}
                // we don't want to display a boxed in field since it's already boxed in by the accordion
                displayObjectBox={false}
                displayOneOfBox={false}
                name={String(index)}
                prefix={`${controlName}`}
                schema={schemasToCreate[index]}
              />
            </CWAccordionPanelContent>
          </CWAccordionPanel>
        )
      })}
      <AddNewButton onSelect={onSelect} schema={schema} />
    </div>
  )
}

function getControlNameToFocus(schemas: FieldType[], prefix = ''): string {
  const indexOfLast = schemas.length - 1
  const lastSchema = schemas[indexOfLast]
  let controlPath = prefix ? `${prefix}.${indexOfLast}` : String(indexOfLast)
  if (lastSchema.type === 'object') {
    controlPath += '.' + Object.keys(lastSchema.properties)[0]
  }
  return controlPath
}
