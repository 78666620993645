import * as y from 'yup'

/**
 * Generates a schema for API keys.
 *
 * The schema expects a string that matches a specific pattern:
 * must be of the form "TEST_API_KEY:...", "LIVE_API_KEY:...", "SMOK_API_KEY:...","SAND_API_KEY:...",.
 * @returns The validation schema for API keys.
 */
export const getApiKeySchema = () => {
  return y
    .string()
    .required()
    .matches(
      /(?:TEST|LIVE|SMOK|SAND)_API_KEY:[a-z0-9]*:[a-z0-9]*|^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/gm,
      {
        name: 'apiKey',
      },
    )
}
