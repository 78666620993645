import { useCallback, useState } from 'react'

import { FixedBanner, Icon, Modal, TextLink } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { useModal } from '@circlefin/modal-router'
import { routes } from '@features/common.constants'
import { SendFeedbackDocument } from '@features/common.graphql'
import { useMutation } from '@shared/apollo'
import useTranslation from 'next-translate/useTranslation'

import { FeedbackScore } from './FeedbackScore'

import type { SubmitHandler } from '@circlefin/form/useForm'

const schema = y.object().shape({
  email: y.string().email().required(),
  feedback: y.string(),
  score: y.number().required(),
  completed: y.boolean(),
})

type FormSchema = y.InferType<typeof schema>

export const Feedback: React.FC = () => {
  const { t } = useTranslation('common')
  const [Form] = useForm<FormSchema>({ schema })
  const [sendFeedback] = useMutation(SendFeedbackDocument, {
    onError: () => {},
  })
  const [error, setError] = useState(false)
  const { router: modalRouter } = useModal()

  const handleSubmit: SubmitHandler<FormSchema> = useCallback(
    async (values) => {
      setError(false)
      await sendFeedback({
        variables: {
          input: {
            completed: values.completed ?? false,
            email: values.email,
            feedback: values.feedback ?? '',
            score: values.score,
          },
        },
        onCompleted: (result) => {
          if (result.sendFeedback === true) {
            modalRouter.push({
              pathname: routes.success,
              query: { email: values.email },
            })
          } else {
            setError(true)
          }
        },
      })
    },
    [sendFeedback, modalRouter],
  )

  const openFeatureRequestForm = useCallback(() => {
    modalRouter.push(routes.featureRequest)
  }, [modalRouter])

  return (
    <>
      <Modal.Title className="type-h-title-lg">{t`modals.sendFeedback.title`}</Modal.Title>
      <Modal.Body className="pt-4">
        <Form onSubmit={handleSubmit}>
          {error ? (
            <div className="pt-4">
              <FixedBanner level="page" status="error" multiline visible>
                <FixedBanner.Title>{t`modals.sendFeedback.error.title`}</FixedBanner.Title>
                <FixedBanner.Description>
                  {t`modals.sendFeedback.error.description`}
                </FixedBanner.Description>
              </FixedBanner>
            </div>
          ) : null}
          <Form.Input
            label={t`modals.sendFeedback.labels.email`}
            name="email"
          />
          <Form.Checkbox
            className="py-4"
            label={t`modals.sendFeedback.labels.completed`}
            name="completed"
          />
          <FeedbackScore />
          <Form.Textarea
            className="pt-4"
            label={t`modals.sendFeedback.labels.feedback`}
            name="feedback"
          />
          <div className="pt-6">
            <Form.SubmitButton className="w-full" variant="primary">
              {t`sendMessage`}
            </Form.SubmitButton>
          </div>
        </Form>
        <div className="grid grid-cols-3 gap-4 mt-6">
          <div>
            <div className="mb-3 type-body-sm-bold">{t`modals.sendFeedback.reachOut.title`}</div>
            <a
              className="type-body-sm text-interactive"
              href={`mailto:${t('modals.sendFeedback.reachOut.email')}`}
            >{t`modals.sendFeedback.reachOut.email`}</a>
          </div>
          <div>
            <div className="mb-3 type-body-sm-bold">{t`modals.sendFeedback.discord.title`}</div>
            <TextLink.Link
              className="type-body-base-bold"
              href={t`modals.sendFeedback.discord.link`}
              variant="primary"
            >
              <Icon name="Social/Discord" />
              {t`modals.sendFeedback.discord.action`}
            </TextLink.Link>
          </div>
          <div>
            <div className="mb-3 type-body-sm-bold">{t`modals.sendFeedback.featureRequest.title`}</div>
            <TextLink.Link
              className="type-body-base-bold"
              onClick={openFeatureRequestForm}
              variant="primary"
            >
              {t`modals.sendFeedback.featureRequest.action`}
            </TextLink.Link>
          </div>
        </div>
      </Modal.Body>
    </>
  )
}
