'use client'

import { useCallback, useRef, type ReactNode } from 'react'
import { createPortal } from 'react-dom'
import Split from 'react-split'

import classNames from 'classnames'

export interface SplitPaneProps {
  /**
   * Optional className that should be applied to the wrapper.
   */
  className?: string
  /**
   * Children for the split pane. Must be an array with two elements.
   */
  children: [ReactNode, ReactNode]
  /**
   * Minimum size for both children.
   */
  minSize?: number
  /**
   * Initial size distribution for the children, expressed as percentages.
   */
  sizes?: [number, number]
}

export const SplitPane: React.FC<SplitPaneProps> = ({
  children,
  className,
  minSize = 250,
  sizes = [25, 75],
}) => {
  const gutterContainer = useRef<HTMLElement>(document.createElement('div'))

  const gutter = useCallback(() => gutterContainer.current, [])

  return (
    <>
      <div className="flex sm:hidden flex-col w-full h-full">
        <div className="h-full">{children[0]}</div>
        <div className="flex-1 h-full">{children[1]}</div>
      </div>
      <Split
        className={classNames('w-full sm:flex flex-row hidden', className)}
        cursor="col-resize"
        data-testid="splitpane"
        direction="horizontal"
        dragInterval={1}
        expandToMin={false}
        gutter={gutter}
        gutterAlign="center"
        gutterSize={2}
        minSize={minSize}
        sizes={sizes}
        snapOffset={30}
      >
        <div className="flex" data-testid="splitpane-first">
          <div className="flex-1 overflow-y-auto">{children[0]}</div>
        </div>
        <div className="flex" data-testid="splitpane-second">
          <div className="flex-1 overflow-y-auto">{children[1]}</div>
        </div>
      </Split>
      {createPortal(
        <div
          className="w-full h-full relative cursor-ew-resize-resize bg-black-50"
          data-testid="splitpane-gutter"
        >
          <div className="absolute top-1/2 -left-1 -mt-5 w-2.5 bg-black-50 border border-black-100 rounded-lg shadow-sm h-10 z-40 cursor-ew-resize-resize flex items-center justify-center" />
        </div>,
        gutterContainer.current,
      )}
    </>
  )
}
