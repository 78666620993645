import {
  useCallback,
  useMemo,
  useRef,
  useState,
  type PropsWithChildren,
} from 'react'

import {
  PLAYGROUND_FORM_CHANGE_EVENT,
  PLAYGROUND_FORM_VALIDATION_STATE_EVENT,
  type PlaygroundEventEmitterType,
  type FormChangeEventPayload,
} from '@features/playground.constants/events'
import { PlaygroundContext } from '@features/playground.hooks/usePlaygroundContext'
import Emittery from 'emittery'

import type { Operation } from '@features/playground.graphql'
import type {
  PlaygroundActions,
  PlaygroundContextType,
} from '@features/playground.hooks/usePlaygroundContext'

/**
 * Props for the PlaygroundProvider component.
 */
export type PlaygroundProviderProps = PropsWithChildren & {
  /**
   * The current API operation being used in the playground.
   */
  operation: Operation
}

/**
 * Provider component for the API Playground context.
 * This component initializes the API Playground state and provides it to all child components.
 * It manages the form input, validation state, selected operation, and project key, and provides methods
 * to update these values through a context.
 */
export const PlaygroundProvider: React.FC<PlaygroundProviderProps> = ({
  children,
  operation,
}) => {
  // Initialize the event emitter for playground events
  const [emitter] = useState(() => new Emittery<PlaygroundEventEmitterType>())

  // Initialize the mutable references for the playground state
  const input = useRef<FormChangeEventPayload>({})
  const valid = useRef<boolean>(true)

  /**
   * Handles form changes by updating the input state and emitting a change event.
   * @param data - The new form data.
   */
  const handleFormChange = useCallback(
    (data: FormChangeEventPayload) => {
      input.current = data
      void emitter.emit(PLAYGROUND_FORM_CHANGE_EVENT, data)
    },
    [emitter],
  )

  /**
   * Handles form validation state changes by updating the valid state and emitting a validation event.
   * @param isValid - The new validation state.
   */
  const handleFormValidationStateChange = useCallback(
    (isValid: boolean) => {
      valid.current = isValid
      void emitter.emit(PLAYGROUND_FORM_VALIDATION_STATE_EVENT, isValid)
    },
    [emitter],
  )

  // Memoize the context value to prevent unnecessary re-renders
  const value = useMemo<PlaygroundContextType>(
    () => [
      { input, valid, operation },
      {
        emitter,
        handleFormChange,
        handleFormValidationStateChange,
      } as PlaygroundActions,
    ],
    [emitter, handleFormChange, handleFormValidationStateChange, operation],
  )

  return (
    <PlaygroundContext.Provider value={value}>
      {children}
    </PlaygroundContext.Provider>
  )
}
