import type React from 'react'
import { useCallback, useEffect, useState } from 'react'

import {
  CWButton,
  CWHeading,
  CWHeadingHeader,
  CWIcon,
} from '@features/common.components'
import { CodePanel } from '@features/playground.components'
import { ExecuteOperationDocument } from '@features/playground.graphql'
import { usePlaygroundState } from '@features/playground.hooks/usePlaygroundState'
import { useMutation } from '@shared/apollo'
import useTranslation from 'next-translate/useTranslation'

/**
 * Response component for displaying and managing API operation responses.
 *
 * This component allows users to send API requests, view responses, and resend requests.
 * It uses the playground state to manage the operation and input data.
 */
export const Response: React.FC = () => {
  const { t } = useTranslation('playground')
  const { valid, input, operation } = usePlaygroundState()
  const [hasBeenCalled, setHasBeenCalled] = useState<boolean>(false)
  const [executeOperation, { data, loading }] = useMutation(
    ExecuteOperationDocument,
    { onError: () => {} },
  )

  /**
   * Handles the execution of the API operation.
   *
   * This function is called when the user clicks the "Send Request" or "Resend Request" button.
   * It executes the operation if the input is valid and updates the hasBeenCalled state.
   */
  const handleExecuteOperation = useCallback(() => {
    if (valid) {
      void executeOperation({
        variables: {
          input: {
            identifier: operation.slug,
            ...input,
          },
        },
        onCompleted: () => setHasBeenCalled(true),
      })
    }
  }, [executeOperation, valid, input, operation])

  useEffect(() => {
    setHasBeenCalled(false)
  }, [operation])

  return (
    <div className="w-full space-y-4" data-testid="operation-response">
      <div className="w-full flex items-center justify-between">
        <CWHeading variant="title/sm">
          <CWHeadingHeader>{t('playground.response')}</CWHeadingHeader>
        </CWHeading>
        {hasBeenCalled && (
          <CWButton
            className="flex-shrink-0"
            disabled={!valid}
            loading={loading}
            onClick={handleExecuteOperation}
            size="sm"
            variant="text"
          >
            <CWIcon className="mr-1" name="RefreshOutline" size={13} />
            {t('playground.resendRequest')}
          </CWButton>
        )}
      </div>
      <div className="w-full rounded-sm border border-neutral-subtle bg-code overflow-hidden min-h-20">
        {!loading && data ? (
          <CodePanel
            code={JSON.stringify(data.executeOperation.body, null, 2)}
            styled={false}
          />
        ) : (
          <div className="w-full p-6 flex items-center justify-center">
            <CWButton
              disabled={!valid}
              loading={loading}
              onClick={handleExecuteOperation}
              size="sm"
              variant="primary"
            >
              {t('playground.sendRequest')}
              <CWIcon className="ml-2" name="PaperAirPlaneSolid" size={16} />
            </CWButton>
          </div>
        )}
      </div>
    </div>
  )
}
