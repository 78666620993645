import { useCallback, useState } from 'react'
import type { SubmitHandler } from 'react-hook-form'

import { Button, Modal } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { useModal } from '@circlefin/modal-router'
import { routes } from '@features/common.constants'
import { SubmitFeatureRequestDocument } from '@features/common.graphql'
import { useMutation } from '@shared/apollo'
import useTranslation from 'next-translate/useTranslation'

import { FeatureRequestConsent } from './FeatureRequestConsent'
import { FeatureRequestProducts } from './FeatureRequestProducts'
import { FeatureRequestType } from './FeatureRequestType'

const schema = y.object().shape({
  requestType: y.string().required(),
  products: y.array(),
  description: y.string().required(),
  email: y.string().email().required(),
  consent: y.string().required(),
})

type FeatureRequestFormValue = y.InferType<typeof schema>

type Steps = 0 | 1 | 2 | 3 | 4

interface Step {
  question: string
  content: React.ReactNode
  prevButton: boolean
  nextDisabled: boolean
}

export const FeatureRequestForm: React.FC = () => {
  const { t } = useTranslation('common')

  const [submitFeatureRequest] = useMutation(SubmitFeatureRequestDocument, {
    onError: () => {},
  })

  const modal = useModal()
  const [currentStep, setCurrentStep] = useState<Steps>(0)
  const [Form, { watch, formState }] = useForm({
    schema,
    defaultValues: {
      consent: 'Yes',
      email: '',
    },
    mode: 'all',
  })

  const formVariables = watch()

  const handleNextStep = useCallback(() => {
    setCurrentStep((prevStep) => (prevStep + 1) as Steps)
  }, [])

  const handlePrevStep = useCallback(() => {
    setCurrentStep((prevStep) => (prevStep - 1) as Steps)
  }, [])

  const handleSubmit: SubmitHandler<FeatureRequestFormValue> = useCallback(
    async (data) => {
      const sendData = {
        ...data,
        products: data.products || [''],
        consent: data.consent === 'Yes',
        source: 'Learn',
      }

      await submitFeatureRequest({
        variables: {
          input: sendData,
        },
        onCompleted: () => {
          modal.router.push({
            pathname: routes.success,
            query: { email: sendData.email },
          })
        },
      })
    },
    [submitFeatureRequest, modal],
  )

  const steps: Record<Steps, Step> = {
    0: {
      question: t`modals.submitFeatureRequest.questions.requestType`,
      content: <FeatureRequestType />,
      prevButton: false,
      nextDisabled: !formVariables.requestType,
    },
    1: {
      question: t`modals.submitFeatureRequest.questions.products`,
      content: <FeatureRequestProducts />,
      prevButton: true,
      nextDisabled: false,
    },
    2: {
      question: t`modals.submitFeatureRequest.questions.description`,
      content: (
        <Form.Textarea
          className="w-full"
          data-testid="featureRequestDescription"
          name="description"
          resizable={false}
        />
      ),
      prevButton: true,
      nextDisabled: !formVariables.description,
    },
    3: {
      question: t`modals.submitFeatureRequest.questions.email`,
      content: (
        <Form.Input
          className="w-full"
          data-testid="featureRequestEmail"
          name="email"
        ></Form.Input>
      ),
      prevButton: true,
      nextDisabled: !!formState.errors.email || !formVariables.email,
    },
    4: {
      question: t`modals.submitFeatureRequest.questions.consent`,
      content: <FeatureRequestConsent />,
      prevButton: true,
      nextDisabled: !formState.isValid,
    },
  }

  /**
   * Render the previous and next buttons based on the current step.
   */
  const renderFooter = (
    currentStep: number,
    handleNextStep: () => void,
    handlePrevStep: () => void,
    nextDisabled: boolean,
  ) => {
    /**
     * Store the index of the first step.
     */
    const firstStep = 0

    /**
     * Store the index of the last step.
     */
    const lastStep = 4

    return (
      <div className="grid grid-flow-col gap-4 pt-5">
        <Modal.Footer
          className="pt-0"
          variant={currentStep === firstStep ? 'stretch' : 'stretch-fraction'}
        >
          {currentStep > firstStep && (
            <Button
              data-testid="backButton"
              onClick={handlePrevStep}
              variant="secondary"
            >
              {t`modals.submitFeatureRequest.button.back`}
            </Button>
          )}
          {currentStep === lastStep ? (
            <Form.SubmitButton
              data-testid="submitButton"
              disabled={nextDisabled}
              variant="primary"
            >
              {t`modals.submitFeatureRequest.button.submit`}
            </Form.SubmitButton>
          ) : (
            <Button
              data-testid="nextButton"
              disabled={nextDisabled}
              onClick={handleNextStep}
              variant="primary"
            >
              {t`modals.submitFeatureRequest.button.next`}
            </Button>
          )}
        </Modal.Footer>
      </div>
    )
  }

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Modal.Header title={t`modals.submitFeatureRequest.title`} hideBack />
        <Modal.Body>
          <div className="flex flex-col gap-8 w-full">
            <div className="type-body-base text-center w-full">
              {t`modals.submitFeatureRequest.description`}
            </div>
            <div>
              <div className="type-body-base pb-4">
                {steps[currentStep].question}
              </div>
              <div>{steps[currentStep].content}</div>
            </div>
          </div>
        </Modal.Body>
        {renderFooter(
          currentStep,
          handleNextStep,
          handlePrevStep,
          steps[currentStep].nextDisabled,
        )}
      </Form>
    </div>
  )
}
