import { Radio } from '@circlefin/components'
import { useController } from '@circlefin/form'
import useTranslation from 'next-translate/useTranslation'

/**
 * Display the type of feature request.
 */
export const FeatureRequestType: React.FC = () => {
  const { field } = useController<{ requestType: string }, 'requestType'>({
    name: 'requestType',
  })
  const { t } = useTranslation('common')

  const content = [
    {
      icon: t`modals.submitFeatureRequest.requestTypes.0.icon`,
      description: t`modals.submitFeatureRequest.requestTypes.0.description`,
    },
    {
      icon: t`modals.submitFeatureRequest.requestTypes.1.icon`,
      description: t`modals.submitFeatureRequest.requestTypes.1.description`,
    },
    {
      icon: t`modals.submitFeatureRequest.requestTypes.2.icon`,
      description: t`modals.submitFeatureRequest.requestTypes.2.description`,
    },
  ]

  return (
    <Radio.Group
      className="flex items-center justify-center w-full"
      data-testid="featureRequestTypes"
      direction="vertical"
      label=" "
      name="requestType"
      onChange={field.onChange}
      value={field.value}
    >
      {content.map((item) => (
        <Radio
          key={item.description}
          className="type-body-base"
          value={item.description}
        >
          {item.icon} {item.description}
        </Radio>
      ))}
    </Radio.Group>
  )
}
