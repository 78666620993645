'use client'

import type { PropsWithChildren } from 'react'

import {
  algoliaModalRegistry,
  AlgoliaModalRouter,
} from '@features/algolia.modals'
import { ModalProvider } from '@features/common.containers'
import { commonModalRegistry, CommonRouter } from '@features/common.modals'
import {
  playgroundModalRegistry,
  PlaygroundModalRouter,
} from '@features/playground.modals'

const registry = {
  ...algoliaModalRegistry,
  ...commonModalRegistry,
  ...playgroundModalRegistry,
}

const ModalRouters = () => {
  return (
    <>
      <AlgoliaModalRouter />
      <CommonRouter />
      <PlaygroundModalRouter />
    </>
  )
}

export const AppModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <ModalProvider registry={registry} routers={ModalRouters}>
      {children}
    </ModalProvider>
  )
}
