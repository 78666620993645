import { Radio } from '@circlefin/components'
import { useController } from '@circlefin/form'
import useTranslation from 'next-translate/useTranslation'

/**
 * Display the user's contact consent for feature request.
 */
export const FeatureRequestConsent: React.FC = () => {
  const { field } = useController<{ consent: string }, 'consent'>({
    name: 'consent',
  })
  const { t } = useTranslation('common')

  return (
    <Radio.Group
      className="flex w-full"
      data-testid="featureRequestConsent"
      direction="vertical"
      label=" "
      name="consent"
      onChange={field.onChange}
      value={field.value}
    >
      <Radio
        key={t`modals.submitFeatureRequest.yes`}
        className="type-body-base"
        value="Yes"
      >
        {t`modals.submitFeatureRequest.yes`}
      </Radio>
      <Radio key={t`modals.submitFeatureRequest.no`} value="No">
        {t`modals.submitFeatureRequest.no`}
      </Radio>
    </Radio.Group>
  )
}
