import {
  CWHeading,
  CWHeadingHeader,
  CWHeadingIntro,
} from '@features/common.components'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

/**
 * Placeholder component displayed when no operation is selected.
 * @returns A Statement component with welcome message and instructions.
 */
export const Placeholder: React.FC = () => {
  const { t } = useTranslation('playground')

  return (
    <div
      className="w-full h-full flex items-start justify-start p-6 flex-col xl:flex-row relative max-w-screen-xl"
      data-testid="playground-placeholder"
    >
      <CWHeading className="w-128" variant="page/sm">
        <CWHeadingHeader as="h1">{t`playground.placeholder.title`}</CWHeadingHeader>
        <CWHeadingIntro className="mt-3">{t`playground.placeholder.subtitle`}</CWHeadingIntro>
      </CWHeading>
      <div className="block xl:absolute right-0 top-0">
        <Image
          alt={t`playground.placeholder.backgroundImageAlt`}
          height={687}
          src="/images/PlaygroundBackground.png"
          width={556}
        />
      </div>
    </div>
  )
}
