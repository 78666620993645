import type { FC } from 'react'

import { CWButton, CWIcon } from '@features/common.components'
import classNames from 'classnames'
import Link from 'next/link'

import { ScopeSelector } from '../../FullScreenPlayground/Sidebar/ScopeSelector/ScopeSelector'
import { SearchField } from '../../FullScreenPlayground/Sidebar/SearchField/SearchField'
import { ProjectList } from '../ProjectList/ProjectList'

import type { ApiReferenceChildProps } from '../ApiReferenceNavigation'

export const ApiReferenceSharedNavigation: FC<ApiReferenceChildProps> = ({
  operationGroups,
  currentScope,
  projects,
  selectedOperation,
  handleScopeChange,
  query,
  setQuery,
  className,
  link,
  groupName,
  onOperationSelection,
}) => {
  return (
    <>
      <>
        <Link className="block px-2" href="/">
          <CWButton
            className="pl-0"
            label="All Docs"
            size="base"
            variant="text"
          >
            <CWIcon className="mr-2" name="ArrowNarrowLeftSolid" size={24} />
            All Docs
          </CWButton>
        </Link>
        {groupName && (
          <div className="type-h-title-md px-2 mb-6 mt-2">{groupName}</div>
        )}
      </>
      <div className={classNames('w-full', className)}>
        {operationGroups && currentScope && (
          <div
            className={classNames(
              'w-full pb-6 flex items-center justify-center flex-col gap-2',
            )}
            data-testid="sidebar-header"
          >
            <ScopeSelector
              currentScope={currentScope}
              items={operationGroups.map((group) => ({
                label: group.name,
                value: group.key,
              }))}
              onScopeChange={handleScopeChange}
            />
            <SearchField onQueryChange={setQuery} query={query} />
          </div>
        )}
        <ProjectList
          link={link}
          onOperationSelection={onOperationSelection}
          projects={projects}
          query={query}
          selectedOperation={selectedOperation}
        />
      </div>
    </>
  )
}
