import { createFormCheckbox } from '@circlefin/form/Form.Checkbox'

import { InputType } from '../InputType/InputType'
import { getInputLabel } from '../getInputLabel/getInputLabel'

import type { FormInputFieldProps } from '../types'
import type { Field } from '@shared/openapi/types'

export type BooleanInputFieldProps = FormInputFieldProps<Field.BooleanField>

const Checkbox = createFormCheckbox()

export const BooleanInputField: React.FC<BooleanInputFieldProps> = ({
  schema,
  name,
  prefix,
}) => {
  return (
    <div
      className="flex items-center justify-start gap-2 relative w-full"
      data-testid="inputfield-boolean"
    >
      <Checkbox
        className="type-body-sm-bold text-neutral [&_.checkbox]:top-0 items-center"
        label={getInputLabel({
          schema,
          name,
        })}
        name={prefix ? [prefix, name].join('.') : name}
      />
      <InputType schema={schema} />
    </div>
  )
}
