import type React from 'react'

import { useController } from '@circlefin/form'
import { createFormInput } from '@circlefin/form/Form.Input'

import { getInputLabel } from '../../../getInputLabel/getInputLabel'

import type { FormInputFieldProps } from '../../../types'
import type { Field } from '@shared/openapi/types'

const Input = createFormInput()

export type StringEnumSingleProps = FormInputFieldProps<Field.StringField>

/**
 * Displays a single, read-only enum value for a string field.
 */
export const StringEnumSingle: React.FC<StringEnumSingleProps> = ({
  name,
  schema,
  prefix,
}) => {
  const fieldName = prefix ? `${prefix}.${name}` : name
  const label = getInputLabel({ schema, name })
  const { field } = useController({
    name: fieldName,
    defaultValue: schema.enum?.[0],
  })

  return (
    <Input
      data-testid="string-enum-single"
      label={label}
      name={field.name}
      onBlur={field.onBlur}
      onChange={field.onChange}
      placeholder={schema.example}
      value={field.value as string}
      disabled
    />
  )
}
