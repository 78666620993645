import type { FC } from 'react'
import { memo, useMemo } from 'react'
import Markdown from 'react-markdown'

import {
  CWAccordionPanel,
  CWAccordionPanelContent,
  CWAccordionPanelHeader,
  CWChip,
} from '@features/common.components'
import useTranslation from 'next-translate/useTranslation'

import type { Translate } from 'next-translate'

export interface ParameterProps {
  /**
   * The displayed title of the parameter.
   */
  name: string
  /**
   * The type of the parameter.
   */
  type: string
  /**
   * The sub type of the child parameters for an array parameter.
   */
  arrayType?: string | null
  /**
   * The title of the parameter.
   */
  title?: string | null
  /**
   * Indicates whether the parameter is required.
   */
  required?: boolean | null
  /**
   * The description of the parameter, formatted using Markdown.
   */
  content: string
  /**
   * The allowed values for the parameter. E.g. Enums.
   */
  allowedValues?: string[]
  /**
   * The child parameters of the parameter, e.g. in case of an object etc. .
   */
  childParameters?: (ParameterProps | null)[] | null
  /**
   * Range of values that should be displayed.
   */
  range?: [number, number]
  /**
   * Default value that should be displayed.
   */
  defaultValue?: number
}

const ParameterComponent: FC<ParameterProps> = ({
  name,
  required,
  type,
  arrayType,
  title,
  content,
  allowedValues,
  childParameters,
  range,
  defaultValue,
}) => {
  const { t } = useTranslation('playground')

  const typeString = useMemo(
    () => getTypeString(t, type, arrayType, childParameters),
    [t, type, arrayType, childParameters],
  )

  /*
    if this parameter is of type 'oneOf'/'anyOf' or an array of type 'oneOf'/'anyOf'
    we want to display the child parameters as options directly with no accordion
  */
  const displayAsOptions = useMemo(() => {
    const optionsTypes = ['oneOf', 'anyOf']
    return optionsTypes.includes(type) || optionsTypes.includes(arrayType ?? '')
  }, [type, arrayType])

  /*
    if this parameter is an option (parent is of type 'oneOf'/'anyOf')
    we want to display the parameter in a card format
  */
  const displayAsOptionCard = useMemo(
    () => name.toLocaleLowerCase().includes('option'),
    [name],
  )

  if (displayAsOptionCard) {
    return (
      <div className="border border-neutral-subtle rounded-md p-4 space-y-2 overflow-hidden text-ellipsis">
        <div className="capitalize type-body-base">
          <span className="type-body-base-bold">{name}:</span>{' '}
          {title ?? typeString}
        </div>

        {childParameters && childParameters.length > 0 && (
          <div>
            <CWAccordionPanel open>
              <CWAccordionPanelHeader>
                <div className="type-body-base-bold text-neutral-strong text-left">
                  {t('parameter.showProperties', {
                    count: childParameters.length,
                  })}
                </div>
              </CWAccordionPanelHeader>
              <CWAccordionPanelContent>
                <div className="flex flex-col divide-y divide-neutral-subtle">
                  {childParameters.map(
                    (child) =>
                      child && <Parameter key={child.name} {...child} />,
                  )}
                </div>
              </CWAccordionPanelContent>
            </CWAccordionPanel>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className="flex flex-col space-y-2 py-4" data-testid="parameter">
      <div className="flex space-x-2 items-center">
        <div className="font-mono text-neutral-strong font-[700]">{name}</div>
        <div className="type-body-sm">{typeString}</div>
        {required ? (
          <div className="type-body-sm text-error">{t`parameter.required`}</div>
        ) : null}
        {range ? (
          <div className="type-body-sm">
            {t('parameter.range', {
              min: range[0],
              max: range[1],
            })}
          </div>
        ) : null}
        {defaultValue != null ? (
          <div className="type-body-sm">
            {t('parameter.default', {
              value: defaultValue,
            })}
          </div>
        ) : null}
      </div>
      {!!content && (
        <div>
          <Markdown>{content}</Markdown>
        </div>
      )}
      {allowedValues && allowedValues.length > 0 ? (
        <div className="flex items-center space-x-4 pb-2 pt-3">
          <div>{t`parameter.allowedValues`}</div>
          <div className="space-x-2">
            {allowedValues.map((item) => (
              <CWChip key={item} variant="default/neutral">
                {item}
              </CWChip>
            ))}
          </div>
        </div>
      ) : null}

      {childParameters && childParameters.length > 0 && (
        <>
          {displayAsOptions ? (
            <div className="space-y-2 rounded-md">
              {childParameters.map(
                (child) => child && <Parameter key={child.name} {...child} />,
              )}
            </div>
          ) : (
            <div>
              <CWAccordionPanel open>
                <CWAccordionPanelHeader>
                  <div className="type-body-base-bold text-neutral-strong text-left">
                    {t('parameter.showProperties', {
                      count: childParameters.length,
                    })}
                  </div>
                </CWAccordionPanelHeader>
                <CWAccordionPanelContent>
                  <div className="flex flex-col divide-y divide-neutral-subtle">
                    {childParameters.map(
                      (child) =>
                        child && <Parameter key={child.name} {...child} />,
                    )}
                  </div>
                </CWAccordionPanelContent>
              </CWAccordionPanel>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export const Parameter = memo(ParameterComponent)

/**
 * Returns a translated string representing the type of the parameter based on its type, arrayType, and children.
 *
 * If the parameter is not any of these types we just display the type.
 *
 * If the parameter is an `array` we want to display `array` and the types possible in the array.
 *
 * If the parameter is `oneOf/anyOf` we want to display the possible types of the children, but not `oneOf/anyOf`.
 *
 * If the parameter is an `array` of `oneOf/anyOf` we want to display `array` along with the possible types of the children.
 * @param t - The translation function.
 * @param type - The type of the parameter.
 * @param arrayType - The array type of the parameter.
 * @param childParameters - The child parameters of the parameter.
 * @returns A string representing the type of the parameter to be displayed.
 */
const getTypeString = (
  t: Translate,
  type: string,
  arrayType?: string | null,
  childParameters?: (ParameterProps | null)[] | null,
) => {
  // no children & not array -> type
  if (!(childParameters && childParameters.length > 0) && !arrayType)
    return t(`form.input.${type}`)

  // get unique child types in translated form
  const childTypes = Array.from(
    new Set(
      childParameters
        ?.filter((child) => !!child)
        .map((child) => (!child ? '' : t(`form.input.${child.type}`))),
    ),
  )

  // non array
  if (!arrayType)
    return type === 'object'
      ? t(`form.input.${type}`) // object -> type
      : childTypes.join(', or ') // oneOf/anyOf -> list possibilities

  // array with possibly multiple types of children -> array type + unique child types
  if (['oneOf', 'anyOf'].includes(arrayType)) {
    return (
      t('form.input.arrays.arrayOf') +
      childTypes.map((child) => child + 's').join(', ')
    )
  }

  // normal array -> array + array type
  return t(`form.input.arrays.items`, { type: arrayType })
}
